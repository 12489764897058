<template>
  <div class="h-full">
    <div class="fixed w-full h-full top-0 left-0 z-0">
      <img src="images/background.png" class="object-cover w-full h-full" alt="">
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <vue-toastr ref="mytoast"></vue-toastr>
    <div class="flex items-end flex-col px-3 pb-3 z-50" v-show="!isMenuHidden"
      style="max-width: 500px; margin: auto; position: fixed; left: calc(100% - 60px); right: 0px; bottom: 70px; gap: 12px;">
      <button @click="toggleMusic()"
        class="rounded-full bg-[var(--primary-color)] p-3 text-white animate-pulse cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="20" height="20">
          <path fill-rule="evenodd"
            d="M19.952 1.651a.75.75 0 0 1 .298.599v14.053a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.403-4.909l2.311-.66a1.5 1.5 0 0 0 1.088-1.442V6.994l-9 2.572v9.737a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.402-4.909l2.31-.66a1.5 1.5 0 0 0 1.088-1.442V5.25a.75.75 0 0 1 .544-.721l10.5-3a.75.75 0 0 1 .658.122z"
            clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>
    <section id="invitation" class="h-screen fixed top-0 left-0 w-screen z-50 duration-700 ease-out">
      <div class="container-mobile h-screen relative">
        <div class="fixed w-full h-full top-0 left-0 z-0">
          <img src="images/background.png" class="object-cover w-full h-full" alt="">
        </div>
        <FrameComponent />
        <div class="flex flex-col w-full justify-center items-center h-full relative z-10">
          <span class="text-md mb-5 uppercase font-bold"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }">The
            Wedding
            of</span>
          <div class="foto-mempelai"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__zoomIn animate__slower' }">
            <img src="images/2_medium.jpg" draggable="false"
              style="width: 100%; height: 100%; object-fit: cover; border-radius: 250px 250px 0px 0px;">
          </div>
          <span class="mt-5 mb-0 font-accent text-4xl text-[var(--primary-color)]"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">Frixsen
            & Dhini</span>
          <span class="mt-0 mb-6 font-accent text-3xl text-[var(--primary-color)]"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }">23.12.23</span>
          <span class="font-medium"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }">Kepada Yth,</span>
          <span class="font-medium"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }">Bapak/Ibu/Saudara/i</span>
          <div class="mt-5 py-2 px-8 border-2 border-[var(--primary-color)] bg-white rounded-lg"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeIn animate__slower' }">
            <span class="uppercase font-medium text-[var(--primary-color)] text-lg">{{ to }}</span>
          </div>
          <button @click="openInvitation()" v-show="isMenuHidden"
            class="mt-5 text-white bg-[var(--primary-color)] font-medium rounded-lg text-sm px-4 py-2 text-center"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }">Buka
            Undangan</button>
        </div>
      </div>
    </section>

    <section class="min-h-screen relative">
      <div class="container-mobile min-h-screen relative py-20 px-3 overflow-hidden flex items-center justify-center">
        <div class="flex justify-center items-center h-full relative z-10">
          <div
            class="flex justify-start lg:justify-center lg:items-center flex-col lg:flex-row max-w-[1000px] h-full gap-10">
            <div class="mx-auto rounded-tr-full rounded-tl-full overflow-hidden p-[10px] w-[300px]"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }"
              style="border: 3px solid var(--primary-color);">
              <img src="images/1_medium.jpg" draggable="false" class="rounded-tr-full rounded-tl-full"
                style="width: 100%; height: 100%; object-fit: cover;">
            </div>
            <div class="flex flex-col text-center mt-14"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }">
              <span class="text-md uppercase font-bold"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }">The Wedding
                of</span>
              <span class="mt-2 mb-2 font-accent text-4xl text-[var(--primary-color)]"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">Frixsen
                & Dhini</span>
              <span class="mt-0 mb-6 font-accent text-3xl text-[var(--primary-color)]"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }">23.12.23</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-mobile h-full relative py-20 px-3">
        <div class="flex justify-center flex-col items-center h-full">
          <div class="mb-0 font-accent text-2xl text-center text-[var(--primary-color)]"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">Without
            diminishing respect</div>
          <div class="my-4 font-accent text-2xl text-center text-[var(--primary-color)]"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">We</div>
          <div class="mb-5 font-accent text-4xl text-center text-[var(--primary-color)]"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">
            Groom and Bride</div>
          <div class="flex justify-center items-center flex-row h-full">
            <div class="w-6/12">
              <div class="mt-auto" style="width: 100%;">
                <div class="text-center mt-8"
                  v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">
                  <div class="font-accent mb-3 text-[var(--primary-color)]" style="font-size: 24px;">Alfeus
                    Frixsen<br>
                    Eliel
                  </div>
                  <div class="quotes" style="font-size: 14.4px;">Putra Bungsu<br>(Alm.) Bapak Chia Tay Tjie<br>&amp; Ibu
                    Harlinda</div>
                </div>
              </div>
              <div class="mt-10 mx-auto"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInLeft animate__slower' }"
                style="height: 450px; width: 90%; overflow: hidden; border: 3px solid var(--primary-color); border-radius: 250px ; padding: 10px;">
                <img src="images/6.jpg" draggable="false"
                  style="width: 100%; height: 100%; object-fit: cover; border-radius: 250px;">
              </div>
              <div class="flex flex-row w-full justify-center items-center mt-8 gap-3"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInLeft animate__slower' }">
                <a target="_blank" href="https://www.facebook.com/frixsen"
                  class="text-white text-2xl w-10 h-10 flex justify-center items-center bg-[var(--primary-color)] rounded-full shadow-md shadow-gray-300">
                  <i class="ph-duotone ph-facebook-logo"></i>
                </a>
                <a target="_blank" href="https://www.instagram.com/alfeus_frixsen"
                  class="text-white text-2xl w-10 h-10 flex justify-center items-center bg-[var(--primary-color)] rounded-full shadow-md shadow-gray-300">
                  <i class="ph-duotone ph-instagram-logo"></i>
                </a>
              </div>
            </div>
            <div class="absolute text-[12rem] font-latin font-thin text-[var(--primary-color)] mt-56 z-10"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }">
              &</div>
            <div class="w-6/12">
              <div class="mt-auto" style="width: 100%;">
                <div class="text-center mt-8"
                  v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">
                  <div class="font-accent mb-3 text-[var(--primary-color)]" style="font-size: 24px;">Agnes
                    Herdhini<br>
                    Putri
                  </div>
                  <div class="quotes" style="font-size: 14.4px;">Putri Sulung<br>(Alm.) Bapak Fx Heriyanto<br>&amp; Ibu
                    Veronika Pariyah</div>
                </div>
              </div>
              <div class="mt-10 mx-auto"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInRight animate__slower' }"
                style="height: 450px; width: 90%; overflow: hidden; border: 3px solid var(--primary-color); border-radius: 250px; padding: 10px;">
                <img src="images/7b.png" draggable="false"
                  style="width: 100%; height: 100%; object-fit: cover; border-radius: 250px;">
              </div>
              <div class="flex flex-row w-full justify-center items-center mt-8 gap-3"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInRight animate__slower' }">
                <a target="_blank" href="https://www.facebook.com/agnesherdhini"
                  class="text-white text-2xl w-10 h-10 flex justify-center items-center bg-[var(--primary-color)] rounded-full shadow-md shadow-gray-300">
                  <i class="ph-duotone ph-facebook-logo"></i>
                </a>
                <a target="_blank" href="https://www.instagram.com/_agnesjxx"
                  class="text-white text-2xl w-10 h-10 flex justify-center items-center bg-[var(--primary-color)] rounded-full shadow-md shadow-gray-300">
                  <i class="ph-duotone ph-instagram-logo"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="mb-0 text-lg text-center w-full lg:w-6/12 mt-8"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">
            Mengundang Anda untuk hadir dalam sakramen suci pernikahan yang akan diselenggarakan sebagai bentuk cinta yang
            dikaruniakan Tuhan kepada kami.</div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-mobile h-full relative py-20 px-3">
        <div class="flex justify-center flex-col h-full w-full items-center">
          <div class="mb-5 font-accent text-4xl text-center text-[var(--primary-color)]"
            v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">
            Save The Date</div>
          <div class="flex justify-center flex-col bg-white rounded-[2rem] shadow-lg overflow-hidden">
            <div>
              <div class="m-10" v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeIn animate__slower' }"
                v-for="(loc, i) in filtered_locations" :key="i">
                <div class="font-accent text-[var(--primary-color)] text-2xl mb-2">{{ loc.name }}
                </div>
                <div class="w-full">
                  <div class="flex w-full">
                    <i class="ph-fill ph-calendar-check mr-2 mt-1"></i>
                    <span v-html="loc.date"></span>
                  </div>
                  <div class="flex w-full" v-if="loc.time">
                    <i class="ph-fill ph-clock mr-2 mt-1"></i>
                    {{ loc.time }}
                  </div>
                  <div class="flex w-full">
                    <i class="ph-fill ph-map-pin mr-2 mt-1"></i>
                    <div>
                      <span class="font-medium text-[var(--primary-color)]">{{ loc.place }}</span><br />
                      {{ loc.address }}
                    </div>
                  </div>
                </div>
                <div class="text-center mt-3">
                  <div class="mb-5 border-[2px] border-[var(--primary-color)] overflow-hidden rounded-[20px]">
                    <iframe :src="loc.map" width="100%" height="350" style="border:0;" allowfullscreen=""
                      loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                  <a :href="loc.maps" class="bg-[var(--primary-color)] text-white py-2 px-4 rounded-full"
                    target="_blank" draggable="false"><i class="ph-fill ph-map-pin mr-1"></i> Petunjuk Ke Lokasi</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="relative overflow-hidden">
      <img class="h-full w-full absolute z-0 object-cover" v-prlx.mobile src="images/5b.jpg">
      <div class="w-full h-full absolute bg-white/[0.7]"></div>
      <div class="container-mobile h-full relative py-20 px-3 overflow-hidden">
        <div class="flex justify-center flex-col items-center h-full relative z-10">
          <div
            class="flex justify-start lg:justify-center lg:items-center flex-col lg:flex-row max-w-[1000px] h-full gap-10">
            <div class="mx-auto rounded-tr-full rounded-tl-full overflow-hidden p-[10px] w-full"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }"
              style="border: 3px solid var(--primary-color);">
              <img src="images/4_medium.jpg" draggable="false" class="rounded-tr-full rounded-tl-full"
                style="width: 100%; height: 100%; object-fit: cover;">
            </div>
            <div class="flex flex-col text-center mt-14"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }">
              <div class="mb-5 font-accent text-4xl text-center text-[var(--primary-color)]">
                Quotes</div>
              <span class="font-medium mb-2">Kejadian 1:27-28</span>
              <span class="font-medium italic">"Allah menciptakan manusia itu menurut gambar-Nya, menurut gambar Allah
                diciptakan-Nya dia; laki-laki dan perempuan diciptakan-Nya mereka. Allah memberkati mereka, lalu Allah
                berfirman: 'Beranakcuculah dan bertambah banyak, penuhilah bumi dan taklukkanlah itu.'"</span>
            </div>
          </div>
        </div>
      </div>

      <div class="container-mobile h-full relative py-20 px-3">
        <div class="flex justify-center flex-col h-full items-center">
          <div class="w-full lg:w-6/12">
            <div class="mb-0 font-accent text-4xl text-center text-[var(--primary-color)]"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">
              RSVP</div>
            <div class="mb-4 text-center"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }"
              style="font-size: 14.4px;">Reservasi
              Kehadiran</div>
            <div class="mb-2" v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeIn animate__slower' }">
              <input type="text" id="nama" v-model="to"
                class="bg-gray-50 border border-[var(--primary-color)] text-gray-900 text-sm rounded-lg block w-full p-2"
                placeholder="Isi Nama" required>
            </div>
            <div class="mb-2 text-lg text-[var(--primary-color)]"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeIn animate__slower' }">
              <p-radio class="p-default p-round p-fill w-full" name="konfirmasi" color="success" v-model="rsvp.konfirmasi"
                value="Saya akan datang">Saya akan datang</p-radio>
              <p-radio class="p-default p-round p-fill w-full" name="konfirmasi" color="warning" v-model="rsvp.konfirmasi"
                value="Saya masih ragu">Saya masih ragu</p-radio>
              <p-radio class="p-default p-round p-fill w-full" name="konfirmasi" color="danger" v-model="rsvp.konfirmasi"
                value="Maaf, saya tidak bisa datang">Maaf, saya tidak bisa datang</p-radio>
            </div>
            <button @click="rsvpSend()"
              class="text-white bg-[var(--primary-color)] font-medium rounded-lg text-sm w-full px-4 py-2 text-center"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeIn animate__slower' }">Reservation
              via WhatsApp</button>
          </div>
        </div>
      </div>

      <div class="container-mobile h-full relative py-20 px-3">
        <div class="flex justify-center flex-col items-center h-full">
          <div class="w-full lg:w-6/12">
            <div class="mb-0 font-accent text-4xl text-center text-[var(--primary-color)]"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">
              Make a Wish</div>
            <div class="mb-2" v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeIn animate__slower' }">
              <input type="text" id="nama" v-model="to"
                class="bg-gray-50 border border-[var(--primary-color)] text-gray-900 text-sm rounded-lg block w-full p-2"
                placeholder="Isi Nama" required>
            </div>
            <div class="mb-2" v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeIn animate__slower' }">
              <textarea v-model="wish" id="wish" cols="30" rows="10"
                class="bg-gray-50 border border-[var(--primary-color)] text-gray-900 text-sm rounded-lg block w-full p-2"
                placeholder="Isi Pesan" required></textarea>
            </div>
            <button @click="wishSend()"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeIn animate__slower' }"
              class="text-white bg-[var(--primary-color)] font-medium rounded-lg text-sm w-full px-4 py-2 text-center">Send
              Wish</button>

            <perfect-scrollbar v-if="wishes.length > 0" perfect-scrollbar id="comment-box" class="mt-10 h-[600px] py-5">
              <div class="justify-center relative top-1/3 w-full" v-for="(wish, i) in wishes" :key="i"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInLeft' }">
                <div
                  class="relative grid grid-cols-1 gap-4 p-4 mb-8 border-[1px] border-gray-200 rounded-lg bg-white shadow-lg">
                  <div class="relative flex gap-4">
                    <div
                      class="relative rounded-lg -top-[28px] -mb-4 bg-white border-[1px] border-gray-200 h-20 w-20 flex justify-center items-center text-3xl font-extrabold text-[var(--primary-color)]">
                      {{ wish.initial }}
                    </div>
                    <div class="flex flex-col w-full">
                      <div class="flex flex-row justify-between">
                        <p class="relative text-lg whitespace-nowrap truncate overflow-hidden font-medium">{{ wish.name }}
                        </p>
                        <a class="text-gray-500 text-xl" href="#"><i class="fa-solid fa-trash"></i></a>
                      </div>
                      <p class="text-gray-400 text-sm">{{ wish.date }}</p>
                    </div>
                  </div>
                  <p class="-mt-4 text-gray-500">{{ wish.wish }}</p>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-mobile h-full relative py-20 px-3">
        <div class="flex justify-center flex-col h-full items-center">
          <div class="text-center w-full lg:w-6/12">
            <div class="mb-5 font-accent text-4xl text-center text-[var(--primary-color)]"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">
              Send Gift</div>
            <div class="editable mb-4"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }"
              style="font-size: 14.4px;">Terima kasih banyak atas hadiah pernikahan kami yang indah ini, sangat kami hargai dan akan selalu menjadi kenangan istimewa dalam perjalanan hidup kami bersama.
            </div>
            <div class="flex w-full gap-6">
              <button type="button" class="bg-[var(--primary-color)] text-white py-2 px-4 rounded-full w-full"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }"
                style="margin: auto; font-size: 14.4px;" @click="gift = 1">
                🎁 Cashless
              </button>
              <button type="button" class="bg-[var(--primary-color)] text-white py-2 px-4 rounded-full w-full"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }"
                style="margin: auto; font-size: 14.4px;" @click="gift = 2">
                🎁 Wedding Gift
              </button>
            </div>
            <div class="gift-1 mt-3 p-4 rounded flex flex-wrap gap-4 justify-center"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__zoomIn animate__slower' }" v-show="gift == 1">
              <div class="text-center w-full lg:w-auto rounded-lg bg-white p-8 shadow-xl shadow-gray-300"
                v-for="(rek, i) in rekening" :key="i">
                <div class="text-center mb-2">{{ rek.name }}</div>
                <input type="hidden" :id="`rek-${rek.no}`" :value="rek.no">
                <div class="editable font-bold text-xl text-[var(--primary-color)] my-3 flex items-center justify-center">
                  {{ rek.no }} <i class="ph-fill ph-copy cursor-pointer ml-1" @click="copyText(rek.no)"></i></div>
                <div class="text-sm">Atas Nama</div>
                <div class="editable italic">{{ rek.holder }}</div>
              </div>
            </div>
            <div class="gift-2 mt-3 p-4 rounded"
              v-vue-aos.once="{ animationClass: 'animate__animated animate__zoomIn animate__slower' }" v-show="gift == 2">
              <div class="text-center mb-2">
                <div class="font-bold text-lg text-[var(--primary-color)] mb-2">Kirim Kado</div>
                <div class="mb-0">Anda dapat mengirim kado ke:<br><br><span class="font-medium">Jl. Bougenvil Raya No. 3
                    B, RT3 / RW7, Perumahan Griya Abdi Kencana, Purbalingga, 53317</span><br><br>Alfeus Frixsen
                  Eliel<br><span class="italic">089639586360</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-mobile h-full relative py-20 px-3">
        <div class="flex justify-center flex-col h-full">
          <div swtyle="width: 100%">
            <div class="text-center">
              <div class="flex w-full items-center justify-center"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInUp animate__slower' }">
                <img class="w-[650px]" src="images/mid.png" />
              </div>
              <div class="mb-5 font-accent text-4xl text-center text-[var(--primary-color)]"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">
                Thank You</div>
              <div class="editable quotes mb-3" style="font-size: 14.4px;"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">Merupakan
                suatu kebahagiaan bagi kami atas kehadiran Bapak/Ibu untuk
                memberikan doa.</div>
              <div class="editable font-italic mb-3" style="font-size: 14.4px;"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }">Hormat kami yang mengundang</div>
              <div class="editable font-accent h4 text-[var(--primary-color)]"
                v-vue-aos.once="{ animationClass: 'animate__animated animate__fadeInDown animate__slower' }"
                style="font-size: 21.6px;">Frixsen & Dhini</div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { initFlowbite } from 'flowbite'
import FrameComponent from './components/Frame';
import "animate.css"
import Api from './services/api'
import VueToastr from "vue-toastr";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

export default {
  name: 'App',
  components: {
    FrameComponent,
    "vue-toastr": VueToastr,
    Loading,
    PerfectScrollbar
  },
  data() {
    return {
      isLoading: false,
      isMenuHidden: true,
      rsvp: {
        konfirmasi: ''
      },
      wish: '',
      wishes: [],
      gift: 0,
      filtered_locations: [],
      locations: [
        {
          name: "Sangjit",
          maps: "https://maps.app.goo.gl/18nNn9RY5gEpXcyx5",
          place: "Sembagi Resto & Coffee, Hotel Plaza",
          address: "Jl. Tentara Pelajar No.21, Doplang, Kec. Purworejo",
          date: "Sabtu, 9 Desember 2023",
          time: "12:00 - Selesai",
          map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7907.294456387947!2d109.9883503!3d-7.7209463!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7aebd4e4c0a119%3A0x40d486cefa2c490!2sSembagi%20resto%20%26%20coffee!5e0!3m2!1sid!2sid!4v1699639431132!5m2!1sid!2sid"
        },
        {
          name: "Syukuran",
          maps: "https://maps.app.goo.gl/RH8o844m4Z3PXBy59",
          place: "Kediaman Mempelai Pria",
          address: "Jl. Bougenville No.3 B, Bancar, Kec. Purbalingga",
          date: "Kamis - Jumat<br>21 - 22 Desember 2023",
          map : "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3956.637920128878!2d109.3672797!3d-7.3944064!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6559d24b30251b%3A0x9b6dec3496575ea7!2sRumah%20Kutus%20Kutus%20Purbalingga!5e0!3m2!1sid!2sid!4v1699639484631!5m2!1sid!2sid"
        },
        {
          name: "Pemberkatan",
          maps: "https://maps.app.goo.gl/hZB8KJhAyg4ZgZ3KA",
          place: "Gereja Katolik St. Agustinus, Purbalingga",
          address: "Jl. Jend. Sudirman No.188, Bancar, Kec. Purbalingga",
          date: "Sabtu, 23 Desember 2023",
          time: "08:00 - 11:00",
          map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3956.668722569307!2d109.3680338!3d-7.3909686!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e655832beb52ec3%3A0x962a04506417b9f5!2sGereja%20Katolik%20Santo%20Agustinus%2C%20Purbalingga!5e0!3m2!1sid!2sid!4v1699639513983!5m2!1sid!2sid"
        },
        {
          name: "Resepsi",
          maps: "https://maps.app.goo.gl/hQgnC5xqhaMzjMtp8",
          place: "Wisma Asri Purbalingga",
          address: "Jl. Ketuhu No.48, Wirasana, Kec. Purbalingga",
          date: "Sabtu, 23 Desember 2023",
          time: "11:30 - Selesai",
          map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3956.7311000174645!2d109.3610932!3d-7.3840019!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6558310f57f051%3A0x8f3e86dfa31533d2!2sWisma%20Asri%20(%20Tien%20Catering%20)!5e0!3m2!1sid!2sid!4v1699639545985!5m2!1sid!2sid"
        },
      ],
      rekening: [
        {
          name: "BCA",
          no: "0970835814",
          holder: "Alfeus Frixsen Eliel"
        },
        {
          name: "BCA",
          no: "0462236580",
          holder: "Herdhini Putri"
        },
        {
          name: "BNI",
          no: "0967436811",
          holder: "Alfeus Frixsen Eliel"
        },
        {
          name: "BNI",
          no: "0452926887",
          holder: "Herdhini Putri"
        },
        {
          name: "Dana / GoPay / OVO / ShopeePay / LinkAja",
          no: "089639586360",
          holder: "Alfeus Frixsen Eliel "
        },
      ]
    }
  },
  methods: {
    toggleMusic() {
      this.music.muted = !this.music.muted
    },
    openInvitation() {
      const invitationSection = document.querySelector('#invitation')
      invitationSection.style.transform = 'translateY(-100%)'
      this.isMenuHidden = false
      this.music.play()
    },
    copyText(text) {
      let rekInput = document.querySelector(`#rek-${text}`)
      rekInput.setAttribute('type', 'text')
      rekInput.select()

      try {
        document.execCommand('copy');
        window.alert("Nomor rekening berhasil disalin")
      } catch (err) {
        alert('Oops, unable to copy');
      }

      rekInput.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    rsvpSend() {
      if (this.rsvp.konfirmasi != '') {
        const url = `https://api.whatsapp.com/send?phone=6289639586360&text=Halo, saya ${this.to} ingin konfirmasi kehadiran undangan pernikahan kalian bahwa *${this.rsvp.konfirmasi}*, Terima kasih!`
        window.open(url, '_blank', 'noreferrer');
      }
    },
    wishSend() {
      if (this.wish != '') {
        this.isLoading = true
        const data = {
          name: this.to,
          wish: this.wish
        }
        Api.sendWish(data).then(res => {
          if (res.data.success) {
            this.$refs.mytoast.s(res.data.message)
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.wish = '';
          this.getWish();
        })
      }
    },
    getWish() {
      Api.getWish().then(res => {
        this.wishes = res.data.filter(wish => {
          return wish.wish.length > 0
        })

        this.wishes = this.wishes.map(wish => {
          let name = wish.name;
          let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

          let initials = [...name.matchAll(rgx)] || [];

          initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
          ).toUpperCase();

          return {
            name: wish.name,
            wish: wish.wish,
            date: wish.date,
            initial: initials
          }
        })
      }).finally(() => {
        this.isLoading = false
      })
    }
  },
  mounted() {
    initFlowbite();
    this.getWish();
    this.$refs.mytoast.defaultPosition = "toast-top-right";

    if (this.loc == 1) {
      this.filtered_locations = this.locations.filter(location => {
        return location.name == "Syukuran" || location.name == "Pemberkatan" || location.name == "Resepsi"
      })
    } else if (this.loc == 2) {
      this.filtered_locations = this.locations.filter(location => {
        return location.name == "Pemberkatan" || location.name == "Resepsi"
      })
    } else {
      this.filtered_locations = this.locations.filter(location => {
        return location.name == "Syukuran" || location.name == "Pemberkatan"
      })
    }
  },
  setup() {
    const params = new URLSearchParams(window.location.search);
    const to = params.get('to') || 'Tamu Undangan';
    let loc = params.get('loc') || 0;

    if (isNaN(loc) || loc > 3 || loc < 1) {
      loc = 1
    }

    const music = new Audio('audio/music.mp3')
    music.volume = 0.15;
    music.loop = true;

    return {
      to,
      loc,
      music
    }
  },
}
</script>

<style lang="postcss">.ps__rail-y,
.ps__rail-x {
  @apply z-10
}</style>