<template>
    <div>
        <!-- <img class="absolute -left-[80px] -top-[50px] w-[350px] z-0 animate__animated animate__fadeInDown animate__slower"
            src="images/flower-tl.png" />
        <img class="absolute -right-[80px] -bottom-[50px] w-[350px] z-0 animate__animated animate__fadeInUp animate__slower"
            src="images/flower-br.png" /> -->
        <!-- <img class="absolute left-0 top-0 w-full z-0 animate__animated animate__fadeInDown animate__slower"
            src="images/top.png" />
        <img class="absolute left-0 bottom-0 w-full z-0 animate__animated animate__fadeInUp animate__slower"
            src="images/bottom.png" /> -->
        <img class="absolute right-0 top-0 w-[650px] z-0" v-vue-aos="{animationClass:'animate__animated animate__fadeInDown animate__slower'}"
            src="images/top-right.png" />
        <img class="absolute left-0 bottom-0 w-[650px] z-0" v-vue-aos="{animationClass:'animate__animated animate__fadeInUp animate__slower'}"
            src="images/bottom-left.png" />
    </div>
</template>
  
<script>
export default {
    name: 'FrameComponent'
}
</script>
  