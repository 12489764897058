import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@splidejs/splide/css';

// css
import './assets/font.css?v.1'
import './assets/main.css?v.1'

import PrettyCheckbox from 'pretty-checkbox-vue';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';

import VuePrlx from 'vue-prlx'

import VueAos from 'vue-aos'
Vue.use(VueAos)

Vue.use(VuePrlx);

Vue.config.productionTip = false

Vue.use(PrettyCheckbox)

new Vue({
  router,
  render: h => h(App),
  mounted: () => {
    document.dispatchEvent(new Event("x-app-rendered"));
  },
}).$mount('#app')
