import axios from 'axios'
let base_url = 'https://frixsen-dhini.my.id/api'

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

export default {
  sendWish(data) {
    return axios.post(base_url + '/send_wish.php', data)
  },
  getWish() {
    return axios.get(base_url + '/wish.php')
  }
}
